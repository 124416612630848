<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            Turnaround upsell
            <template slot="actions">
                <mercur-button data-test="turnaroundUpsellAddNewButton" @click="triggerAdd" class="btn btn-raised btn-primary">
                    <span>Add new upsell table</span>
                </mercur-button>
            </template>
        </grid-header>
        <mercur-card class="bg-primary" style="min-height: 92px;">
            <pretty-select
                data-test="turnaroundUpsellSelector"
                v-if="turnaroundUpsells.length && !loading"
                v-model="selectedTurnaroundUpsell"
                :options="turnaroundUpsells"
                label="turnaroundUpsellName"
                placeholder="Select existing upsell table"
                color="white"
            ></pretty-select>
            <mercur-spinner v-else-if="loading"/>
        </mercur-card>
        <div v-if="Object.keys(selectedTurnaroundUpsell).length" class="mx-4">
            <div class="row">
                <div class="col">
                    <p class="fs-16 mt-20">{{ selectedTurnaroundUpsell.turnaroundUpsellDescription }}</p>
                    <p>
                        <mercur-button data-test="turnaroundUpsellEditButton" class="btn btn-outline-blue" @click="triggerEdit" :disabled="editingUpsellTable || loading"><i class="fas fa-pen"></i>Edit details</mercur-button>
                        <mercur-button data-test="turnaroundUpsellDuplicateButton" class="btn btn-outline-blue" @click="triggerDuplicate" :disabled="editingUpsellTable || loading"><i class="fas fa-clone"></i>Duplicate table</mercur-button>
                        <mercur-button data-test="turnaroundUpsellDeleteButton" class="btn btn-raised btn-danger m-0 u-float-right" @click="triggerDelete" :disabled="editingUpsellTable || loading">Delete turnaround upsell</mercur-button>
                    </p>
                    <hr>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col d-flex align-items-center justify-content-end">
                    <mercur-button class="btn btn-outline-blue mr-1" v-if="editingUpsellTable" @click="triggerUndo" :disabled="loading"><i class="fas fa-undo"></i> Revert changes</mercur-button>
                    <mercur-button data-test="turnaroundUpsellEditTableButton" class="btn btn-raised mr-15" @click="triggerEditTable" :disabled="loading">{{editingUpsellTable ? 'Cancel' : 'Edit table'}}</mercur-button>
                    <mercur-button data-test="turnaroundUpsellEditTableSaveButton" class="btn btn-raised btn-success" @click="updateUpsellTable" :disabled="!editingUpsellTable || loading">Save changes</mercur-button>
                </div>
            </div>
            <div class="row" v-if="selectedTurnaroundUpsell.turnaroundUpsellTable">
                <div class="col">
                    <turnaround-upsell-table v-bind="$data" ref="upsellTable"></turnaround-upsell-table>
                </div>
            </div>
        </div>

        <mercur-dialog data-test="turnaroundUpsellDeleteModal" :is-open.sync="isDelete">
            <p>Are you sure you want to remove this turnaround upsell?</p>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="turnaroundUpsellDeleteModalConfirmButton" class="btn btn-raised btn-primary" :disabled="loading" @click="remove">Delete</mercur-button>
            </template>
        </mercur-dialog>

        <mercur-dialog data-test="turnaroundUpsellAddNewModal" :is-open.sync="isOpen">
            <template v-if="isDuplicate">
                <h3 class="font-weight-normal">Duplicate turnaround upsell table</h3>
                <p>You can change name and description, but turnaround upsell table will be cloned</p>
            </template>
            <h2 class="mt-0 font-weight-normal" v-else>{{ draft ? 'Edit turnaround upsell table' : 'Add new turnaround upsell table'}}</h2>
            <mercur-input data-test="turnaroundUpsellModalNameInput" v-model.trim="form.turnaroundUpsellName" :class="{'form-invalid': $v.form.turnaroundUpsellName.$error}">
                Turnaround upsell table name
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.turnaroundUpsellName.required">Turnaround upsell table name is required</span>
                    <span class="form-error" v-else-if="!$v.form.turnaroundUpsellName.unique">Turnaround upsell name needs to be unique</span>
                </template>
            </mercur-input>
            <mercur-input data-test="turnaroundUpsellModalDescriptionInput" v-model.trim="form.turnaroundUpsellDescription">
                Turnaround upsell table description
            </mercur-input>
            <template slot="footer">
                <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                <mercur-button data-test="turnaroundUpsellModalDuplicateButton" v-if="isDuplicate" @click="submit" class="btn btn-raised btn-primary" :disabled="loading">Duplicate</mercur-button>
                <mercur-button data-test="turnaroundUpsellModalSaveButton" v-else class="btn btn-raised btn-primary" @click="submit" :disabled="loading">
                    {{ draft ? 'Update turnaround upsell table' : 'Add new turnaround upsell table'}}
                </mercur-button>
            </template>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import PrettySelect from '../../components/utils/PrettySelect'
import TurnaroundUpsellTable from '../../components/pricing/TurnaroundUpsellTable'
import { required } from 'vuelidate/lib/validators'
import { reverseStripProperties } from '@/components/utils/Utils'

const defaultForm = {
    turnaroundUpsellName: '',
    turnaroundUpsellDescription: '',
    turnaroundUpsellTable: [],
}

export default {
    name: 'TurnaroundUpsell',
    components: { GridHeader, PrettySelect, TurnaroundUpsellTable },
    data () {
        return {
            draft: null,
            isOpen: false,
            isEdit: false,
            isDuplicate: true,
            form: defaultForm,
            loading: false,
            isDelete: false,
            turnaroundUpsells: [],
            selectedTurnaroundUpsell: {},
            editingUpsellTable: false,
            selectedUpsellDraft: null,
            selectAll: false,
            selectedRows: [],
        }
    },
    validations: {
        form: {
            turnaroundUpsellName: {
                required,
                unique: function (value, vm) {
                    return this.checkIfUnique(vm)
                },
            },
        },
    },
    watch: {
        selectedTurnaroundUpsell (value) {
            this.$router.push({
                name: 'TurnaroundUpsell',
                params: {
                    turnaroundUpsellId: value.turnaroundUpsellId,
                },
            })
        },
    },
    methods: {
        triggerAdd () {
            this.draft = null
            this.isOpen = true
            this.isDuplicate = false
        },
        triggerEdit () {
            this.draft = JSON.parse(JSON.stringify(this.selectedTurnaroundUpsell))
            this.form = this.draft
            this.isOpen = true
            this.isEdit = true
            this.isDuplicate = false
        },
        triggerClose () {
            this.draft = null
            this.isOpen = false
            this.isDuplicate = false
            this.isEdit = false
            this.form = defaultForm
            this.isDelete = false
        },
        triggerDuplicate () {
            this.isOpen = true
            this.isDuplicate = true
            this.form = JSON.parse(JSON.stringify(this.selectedTurnaroundUpsell))
        },
        triggerDelete () {
            this.isDelete = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.DELETE.replace('{turnaroundUpsellId}', this.selectedTurnaroundUpsell.turnaroundUpsellId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround upsell was deleted',
                    type: 'success',
                })
                const index = this.turnaroundUpsells.findIndex(e => e.turnaroundUpsellId === data.data.turnaroundUpsellId)
                this.triggerClose()
                this.$delete(this.turnaroundUpsells, index)
                this.$set(this, 'selectedTurnaroundUpsell', {})
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        submit () {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            if (this.isEdit) {
                this.updateTurnaroundUpsell()
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.CREATE
            this.loading = true
            let payload = this.form
            if (this.form.turnaroundUpsellId) {
                payload = reverseStripProperties(payload, ['turnaroundUpsellName', 'turnaroundUpsellTable', 'turnaroundUpsellDescription'])
            }

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround upsell table was added',
                    type: 'success',
                })
                if (Array.isArray(data.data.turnaroundUpsellTable)) {
                    data.data.turnaroundUpsellTable = {}
                }
                this.$set(this, 'turnaroundUpsells', [
                    ...this.turnaroundUpsells,
                    data.data,
                ])
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        listTurnaroundUpsells () {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.LIST
            this.loading = true
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                const items = data.data.items
                items.forEach((item, index) => {
                    if (Array.isArray(item.turnaroundUpsellTable)) {
                        items[index].turnaroundUpsellTable = {}
                    }
                })
                this.$set(this, 'turnaroundUpsells', items)
                if (this.$route.params.turnaroundUpsellId) {
                    this.$set(this, 'selectedTurnaroundUpsell', this.turnaroundUpsells.find(e => e.turnaroundUpsellId === this.$route.params.turnaroundUpsellId))
                }
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong fetching data',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        async updateTurnaroundUpsell (data) {
            const url = CONFIG.API.ROUTES.PRICING.TURNAROUND_UPSELL.UPDATE
            this.loading = true
            const payload = data || this.form
            await this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Turnaround upsell table was updated',
                    type: 'success',
                })
                const changedTableIndex = this.turnaroundUpsells.findIndex(e => e.turnaroundUpsellId === data.data.turnaroundUpsellId)
                const newArray = this.turnaroundUpsells
                newArray[changedTableIndex] = data.data
                this.$set(this, 'turnaroundUpsells', newArray)
                this.$set(this, 'selectedTurnaroundUpsell', data.data)
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        checkIfUnique (vm) {
            let upsells = JSON.parse(JSON.stringify(this.turnaroundUpsells))
            if (this.isEdit) {
                upsells.splice(upsells.findIndex(e => e.turnaroundUpsellId === this.selectedTurnaroundUpsell.turnaroundUpsellId), 1)
            }
            const previousNames = upsells.map(e => e.turnaroundUpsellName)
            return !previousNames.includes(vm.turnaroundUpsellName)
        },

        // TABLE METHODS
        updateUpsellTable () {
            if (this.$refs.upsellTable.$v.$invalid) {
                this.$root.$emit('notification:global', {
                    message: `Your table is not valid. Please fix!`,
                    type: 'error',
                })
                return
            }
            this.updateTurnaroundUpsell(this.selectedTurnaroundUpsell).then(() => {
                this.editingUpsellTable = false
                this.selectedUpsellDraft = null
            })
        },
        triggerEditTable () {
            if (this.editingUpsellTable) {
                this.editingUpsellTable = false
                this.triggerUndo()
                this.selectedUpsellDraft = null
                return
            }

            this.editingUpsellTable = true
            this.selectedUpsellDraft = JSON.parse(JSON.stringify(this.selectedTurnaroundUpsell.turnaroundUpsellTable))
        },
        triggerUndo () {
            this.$set(this.selectedTurnaroundUpsell, 'turnaroundUpsellTable', JSON.parse(JSON.stringify(this.selectedUpsellDraft)))
        },
    },
    created () {
        this.listTurnaroundUpsells()
    },
}
</script>
